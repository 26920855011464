<template>
  <div class="chart-problem-container">
    <div class="chart-content">
      <Row>
        <Col span="8">&nbsp;</Col>
        <Col span="8">
          <Date-picker @on-change="changeTimeAll" type="daterange" placeholder="选择日期"></Date-picker>
        </Col>
        <Col span="8">&nbsp;</Col>
      </Row>
      <div class="chart" ref="pieData"></div>
    </div>
    <div class="chart-content">
      <Form :label-width="115" label-position="right"> 
        <Row>
          <Col span="3">&nbsp;</Col>
          <Col span="8">
            <Date-picker @on-change="changeTimeOne" type="daterange" placeholder="选择日期"></Date-picker>
          </Col>
          <Col span="6">
            <Form-item label="问题类型：">
              <Select v-model="searchOne.problem_type" placeholder="请选择">
                <Option :value="v.id" v-for="v in problem_type_list" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="2">
            <Button type="primary" class="search-btn" @click="handleSubmit()">搜索</Button>
          </Col>
          <Col span="4">&nbsp;</Col>
        </Row>
      </Form>
      <div class="chart" ref="lineData"></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import echarts from 'echarts';
import problemService from '@/services/problemService';
import chartService from '@/services/chartService';
export default {
	data() {
		return {
			searchAll: {
				start_time: '',
				end_time: '',
			},
			searchOne: {
				problem_type: 1,
				start_time: '',
				end_time: '',
			},
			pieChart: '',
			lineChart: '',
			xArr: [],
			yArr: [],
			pieData: [],
			pieLegend: [],
			problem_type_list: [],
		};
	},
	created() {
		problemService.getProblemSelect().then((data) => {
			this.problem_type_list = data.problem_type;
			this.searchOne.problem_type = this.problem_type_list[0].id;
		});
	},
	mounted() {
		// echarts实例化
		this.pieChart = echarts.init(this.$refs.pieData);
		this.lineChart = echarts.init(this.$refs.lineData);
		// 初始化实例
		let lineOptions = {
			grid: {
				show: true,
				borderColor: '#e3e3e3',
				bottom: 80,
			},
			color: ['#f18985'],
			xAxis: {
				type: 'category',
				boundaryGap: false,
				axisLine: { onZero: false },
				data: [],
			},
			yAxis: [
				{
					name: '个',
					type: 'value',
				},
			],
			tooltip: {
				trigger: 'axis',
				axisPointer: {
					type: 'cross',
					animation: false,
					label: {
						backgroundColor: '#505765',
					},
				},
			},
			dataZoom: [
				{
					show: true,
				},
				{
					type: 'inside',
				},
			],
			series: [
				{
					name: '问题个数',
					type: 'line',
					symbolSize: 10,
					animation: false,
					symbol: 'circle',
					areaStyle: {
						normal: {
							color: 'transparent',
						},
					},
					lineStyle: {
						normal: {
							width: 3,
							color: '#f18985',
						},
					},
					data: [],
				},
			],
		};
		let pieOptions = {
			tooltip: {
				trigger: 'item',
				formatter: '{b}: {c} ({d}%)',
			},
			title: {
				text: '问题数据',
				textStyle: {
					color: '#fdad00',
					fontWeight: 'normal',
					fontSize: 28,
				},
				subtext: '统计',
				subtextStyle: {
					color: '#999',
					fontWeight: 'normal',
					fontSize: 18,
				},
				left: '49.5%',
				top: '40%',
				textAlign: 'center',
			},
			legend: {
				orient: 'vertical',
				right: 20,
				data: [],
			},
			color: [
				'#6682ea',
				'#66b0ea',
				'#c7a7dc',
				'#fab2c3',
				'#f18985',
				'#94e1f0',
				'#f1ce7e',
				'#60ffba',
			],
			series: [
				{
					type: 'pie',
					clockwise: false,
					startAngle: 60,
					radius: ['50%', '75%'],
					label: {
						normal: {
							show: true,
							position: 'inside',
							formatter: '{d}%',
						},
					},
					data: [],
				},
			],
		};
		this.lineChart.setOption(lineOptions);
		this.lineChart.showLoading();
		this.pieChart.setOption(pieOptions);
		this.pieChart.showLoading();
		this.getProblemAll();
		this.getProblemOne();
	},
	methods: {
		getProblemAll() {
			chartService.getProblemAll(this.searchAll).then((data) => {
				this.pieData = [];
				this.pieLegend = [];
				data.data_list.forEach((item) => {
					this.pieData.push({
						value: item.count,
						name: item.name,
					});
					this.pieLegend.push(item.name);
				});
				this.pieChart.hideLoading();
				this.pieChart.setOption({
					legend: {
						formatter: function (name) {
							let i = 0;
							data.data_list.forEach((item, index) => {
								if (item.name === name) {
									i = index;
								}
							});
							return name + '：' + data.data_list[i].count + ' 起';
						},
						data: this.pieLegend,
					},
					series: [
						{
							data: this.pieData,
						},
					],
				});
			});
		},
		getProblemOne() {
			chartService.getProblemOne(this.searchOne).then((data) => {
				this.xArr = [];
				this.yArr = [];
				data.data_list.forEach((item) => {
					this.xArr.push(item.create_time);
					this.yArr.push(item.count);
				});
				this.lineChart.hideLoading();
				this.lineChart.setOption({
					xAxis: {
						data: this.xArr,
					},
					series: [
						{
							// 根据名字对应到相应的系列
							name: '问题个数',
							data: this.yArr,
						},
					],
				});
			});
		},
		changeTimeOne(value) {
			this.searchOne.start_time = value[0];
			this.searchOne.end_time = value[1];
		},
		changeTimeAll(value) {
			this.searchAll.start_time = value[0];
			this.searchAll.end_time = value[1];
			this.getProblemAll();
		},
		handleSubmit() {
			this.getProblemOne();
		},
	},
};
</script>

<style lang="css" scoped>
</style>
